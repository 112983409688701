@mixin media($screensize) {
    @if ($screensize==Advanced) {
        @media(max-width: 1550px) {
            @content
        }

        ;
    }


    @if ($screensize==Extralarge) {
        @media(max-width: 1400px) {
            @content
        }

        ;
    }

    @if ($screensize==XL) {
        @media(max-width: 1200px) {
            @content
        }

        ;
    }

    @if ($screensize==large) {
        @media(max-width: 992px) {
            @content
        }

        ;
    }

    @if ($screensize==medium) {
        @media(max-width: 768px) {
            @content
        }

        ;

    }

    @if ($screensize==small) {
        @media(max-width: 576px) {
            @content
        }

        ;

    }
}