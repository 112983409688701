@import "./abstract.scss";
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

$primary: #d4af54;
$secondary: #e7c672;
$third: #5d5d5d;
$title: #c89e32;
$gray: #6c6c6c;
$footer: #222831;
$fontOne: 'Sacramento', cursive;
$fontSecond: 'Poppins', sans-serif;
$fontThird: 'Barlow', sans-serif;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $fontSecond;
  position: relative;
  overflow-x: hidden;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


a {
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// yatayda nesneleri yan yana uzunlamasına ortalar
.f-c {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

// yatayda nesneleri yan yana dizer ve dikeyde ortalar
.f-c-a {
  display: flex;
  align-items: center;
}

// yatayda nesneleri alt alta ortalar
.f-c-a-h {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

// yatayda nesneleri alt alta en başa yazar
.f-c-a-s {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

// dikeyde nesneleri alt alta uzunlamasına ortalar
.f-c-h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// dikeyde nesneleri alt alta birini en üste birini en alta olmak üzere ayırır
.f-c-h-b {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.box-cizgi {
  height: 3px;
  background-color: #b7b7b7;
  border: none;
  margin: 20px 0;
}

/*navbar*/
.nav {
  .logo {
    width: 230px;
    margin: 0 auto;
  }

  .my-navbar {
    padding: 10px;
    border-radius: 5px;
    @extend .f-c;
    color: '#fff'
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .nav-button {
    background: none;
    color: #fff;
    border: none;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: 0.3s linear;

    @include media(medium) {
      font-size: 0.6rem;
      margin: 5px;
    }

    &:hover {
      color: #B19D79;
    }
  }

}

/*navbar end*/

/*Home start*/
.home {
  .home-begin {
    position: relative;
    width: 100vw;
    height: 100vh;

    .slider-container {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .slider-content {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        transform: translate(-50%, -50%);

        @include media(Advanced){
          top: 65%;
        }
        
        @include media(Extralarge){
          top: 65%;
        }

        @include media(large) {
          top: 60%;
          width: 90%;
        }

        .title {
          font-family: 'Sacramento', cursive;
          font-size: 2rem;
          text-align: center;
          font-weight: bold;
          color: #BDA881;

          @include media(medium) {
            font-size: 1.5rem;
          }
        }

        .body-text {
          text-align: center;
          font-size: 1.2rem;
          letter-spacing: 1px;
          font-weight: 500;
          font-style: italic;
          color: #fff;
          font-family: 'Poppins', sans-serif;

          @include media(medium) {
            font-size: 0.8rem;
          }
        }

        .slogan {
          text-align: center;
          font-size: 1rem;
          letter-spacing: 1px;
          font-weight: 500;
          font-style: italic;
          color: #fff;
          font-family: 'Barlow', sans-serif;

          @include media(medium) {
            font-size: 0.6rem;
          }
        }
      }

      .slider {
        display: flex;
        transition: transform 1s ease-in-out;

        .slide {
          min-width: 100vw;
          box-sizing: border-box;
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            height: 100vh;
            object-fit: cover;
            transition: transform 1s ease-in-out;
          }

          &:hover img {
            transform: scale(1.1);
          }

          & img {
            animation: zoomIn 8s infinite alternate ease-in-out;
          }
        }

        @keyframes zoomIn {
          0% {
            transform: scale(1);
          }

          100% {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

/*Home end*/

/*Body1 start*/
.body-1 {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  font-family: 'Sacramento', cursive;

  .icon-boxs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media(large) {
      flex-wrap: wrap;
      justify-content: baseline;
    }

    @include media(medium) {
      flex-wrap: wrap;
      justify-content: center;
    }


    .box {
      background: url('../../public/assests/bg.png');
      margin: 10px;

      border-radius: 50%;
      color: #fff;
      width: 180px;
      height: 180px;
      position: relative;
      overflow: hidden;
      transition: 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);

        .overlay {
          opacity: 0.5;
        }
      }

      .overlay {
        position: absolute;
        transition: 0.3s linear;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0.3;
      }

      /* Ikonun konumu ayarlanıyor */
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60% !important;
        transform: translate(-50%, -50%);
      }
    }

    .box-text {
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color: #303133e2;
    }
  }
}

/*body 1 end*/

/*Body2*/
.body-2 {

  display: flex;
  justify-content: center;
  width: 100%;

  .row {
    width: 90%;

    img {
      margin-top: 20px;
    }
  }

  .title {
    font-size: 1.8rem;
    font-family: $fontOne;
    color: #B62343;
  }

  .header {
    font-family: $fontThird;
    font-weight: 700;
  }

}

/*Body2*/

/*Body3*/
.body-3 {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 100%;
  min-height: 400px;
  background-attachment: fixed !important;
  background-position: center center;
  background: url('../../public/assests/skills_parallax.jpg');

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    background-color: #212529;
    z-index: 1;
  }

  .overlay-content {
    position: relative;
    z-index: 2;
    @extend .f-c;
    flex-wrap: wrap;
    width: 100% !important;
    min-height: 400px !important;

    .box {
      @extend .f-c-h;
      margin: 30px;
      width: 300px;
    }

    .icon {
      width: 90px;
      height: 90px;
      color: #BDA881;
    }

    .text {
      font-weight: bold;
      font-size: 1rem;
      margin-top: 10px;
      color: #fff;
    }
  }
}

/*Body3*/

/*Body4*/

.body-4 {
  .header {
    text-align: center;
    font-size: 3rem;
    font-family: $fontOne;
    color: #B62343;
  }

  .card-content {
    @extend .f-c;

    .card-text {
      text-align: center;
      font-size: 1rem;
      font-family: $fontThird;
      font-weight: 600;
      color: #222831;
    }

    .card-icon {
      width: 80px;
      height: 90px;
      color: #B62343;
      font-size: 1rem;
    }
  }


}


/*Body4*/

/*Body5*/
.body-5 {
  .header {
    font-family: $fontOne;
    color: #B62343;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
  }

  .text-1 {
    font-family: $fontThird;
    font-weight: bold;
    font-style: italic;
  }

  .my-carousel .carousel-item {
    position: relative;
    border-radius: 10px;

    img {
      border-radius: 10px;
    }

    .overlay {
      position: absolute;
      border-radius: 10px;
      left: 0;
      top: 0;
      background-color: #212529;
      opacity: 0.7;
      width: 100%;
      height: 100%;
    }

    .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-family: $fontSecond;
      font-size: 1.7rem;
      font-weight: bold;
      font-style: italic;
      z-index: 2;
    }


  }
}

/*Body5*/

/*Body6*/
.body-6 {
  width: 100%;
  background: url('../../public/assests/bg-body.png');
  height: auto;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .contact-us {
    font-size: 2rem;
    font-weight: bold;
    color: #B62343;
    font-family: $fontThird;
    font-style: italic;
    @include media(medium){
      font-size: 1.5rem;
    }
  }

  .name {
    font-size: 1.6rem;
    font-weight: bold;
    color: #B62343;
    font-family: $fontThird;
    font-style: italic;
    margin-left: 20px;
  }

  .phone {
    font-size: 1rem;
    margin-left: 20px;
  }

  .addres {
    font-size: 1rem;
    margin-left: 20px;
  }

  .mail {
    font-size: 1rem;
    margin-left: 20px;
  }
}

/*Body6*/


/*Footer*/

.footer {
  min-height: 500px;
  background-color: #212529;
}

/*Footer*/

.whatsapp {
  background-color: #25D366;
  color: #fff;
  width: 50px;
  height: 50px;
  z-index: 500;
  border-radius: 50%;
  position: fixed;
  bottom: 3%;
  padding: 10px;
  right: 2%;
  animation: pulse 5s infinite; /* Yanıp sönen animasyon */
}

.whatsapp a {
  color: #fff;
}

@keyframes pulse {
  0% {
    background-color: #25D366;
    transform: scale(1);
  }
  50% {
    background-color: #21b8a6; /* Yanıp sönen renk */
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Hafif gölge efekti */
  }
  100% {
    background-color: #25D366;
    transform: scale(1);
  }
}
